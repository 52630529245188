import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Gebyr from "./pages/gebyr";
import Template from "./pages/template";
import Gebyr2025 from "./pages/gebyr2025";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Template>
              <Gebyr2025 />
            </Template>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
