import React, { useState } from "react";
import { Alert, Button, Table, Card } from "react-bootstrap";

import { beregnPris24, beregnPris25 } from "../models/beregning2025";

export default function Gebyr2025() {
  const [vannmaler, setVannmaler] = useState(false);
  const [kvadrat, setKvadrat] = useState(120);

  const [type, setType] = useState("Vann og avløp");

  return (
    <div className="row">
      <div className="col-12">
        <h1 style={{ color: "rgb(12, 89, 124)" }}>
          Gebyrkalkulator for vann og avløp (boligabonnenter)
        </h1>
        <hr />
      </div>
      <div className="col-12 bg-light p-4 rounded">
        {vannmaler && (
          <Alert variant="warning">
            <div className="d-flex flex-row align-items-center">
              <i className="ri-alert-line me-2" style={{ fontSize: 30 }} />
              <div>
                <span style={{ fontWeight: 500 }}>
                  Installasjon av vannmåler kan koste mellom 15 til 20 tusen. I
                  tillegg påløper målerleie.
                </span>{" "}
                <br />
                <span>
                  Målerleie inngår ikke i beregningsgrunnlaget på denne
                  nettsiden.
                </span>
              </div>
            </div>
          </Alert>
        )}
        <Table variant="light">
          <tbody>
            <tr>
              <th>{vannmaler ? "Ant. kubikk" : "Bruksareal (BRA)"}</th>
              <td>
                <input
                  type="number"
                  defaultValue={kvadrat}
                  onChange={(e) => setKvadrat(e.target.value)}
                  style={{ borderRadius: 5, width: "100%" }}
                />
              </td>
            </tr>
            <tr>
              <th>Tilkobling</th>
              <td>
                <select
                  style={{ borderRadius: 5, width: "100%" }}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="Vann og avløp">Vann og avløp</option>
                  <option value="Vann">Vann</option>
                  <option value="Avløp">Avløp</option>
                </select>
              </td>
            </tr>
          </tbody>
        </Table>
        <hr />
        <div className="d-flex justify-content-between">
          <Button size="sm">
            <a
              href="https://lovdata.no/dokument/LF/forskrift/2024-09-11-2446"
              target="_blank"
              className="text-white"
            >
              Forskrift om vann- og avløpsgebyr (Lovdata)
            </a>
          </Button>
          <Button
            size="sm"
            onClick={() => setVannmaler((prev) => !prev)}
            variant="outline-dark"
          >
            {vannmaler ? "Bytt til kvadrat" : "Jeg har vannmåler"}
          </Button>
        </div>
      </div>
      <div className="col-12 bg-warning py-3 px-4 mt-2 rounded-top">
        <h3>Dette koster vann og avløp for deg</h3>
      </div>
      <div className="col-12 bg-light p-4">
        <Table>
          <thead>
            <tr>
              <th style={{ width: "60%" }}>Varelinje</th>
              <th className="text-end">Årsgebyr 2024</th>
              <th className="text-end">Forslag årsgebyr 2025</th>
              <th className="text-end">Endring</th>
            </tr>
          </thead>
          <tbody>
            {(type === "Vann og avløp" || type === "Vann") && (
              <tr>
                <td>Vann</td>
                <td className="text-end">
                  {beregnPris24(vannmaler, kvadrat, "vann").toLocaleString(
                    "nb-no"
                  )}
                </td>
                <td className="text-end">
                  {beregnPris25(vannmaler, kvadrat, "vann").toLocaleString(
                    "nb-no"
                  )}
                </td>
                <td className="text-end">
                  {(
                    beregnPris25(vannmaler, kvadrat, "vann") -
                    beregnPris24(vannmaler, kvadrat, "vann")
                  ).toLocaleString("nb-no")}
                </td>
              </tr>
            )}
            {(type === "Vann og avløp" || type === "Avløp") && (
              <tr>
                <td>Avløp</td>
                <td className="text-end">
                  {beregnPris24(vannmaler, kvadrat, "avløp").toLocaleString(
                    "nb-no"
                  )}
                </td>
                <td className="text-end">
                  {beregnPris25(vannmaler, kvadrat, "avløp").toLocaleString(
                    "nb-no"
                  )}
                </td>
                <td className="text-end">
                  {(
                    beregnPris25(vannmaler, kvadrat, "avløp") -
                    beregnPris24(vannmaler, kvadrat, "avløp")
                  ).toLocaleString("nb-no")}
                </td>
              </tr>
            )}
            <tr>
              <td style={{ fontWeight: 800 }}>Sum</td>
              {type === "Vann og avløp" && (
                <>
                  <td style={{ fontWeight: 800 }} className="text-end">
                    {(
                      beregnPris24(vannmaler, kvadrat, "avløp") +
                      beregnPris24(vannmaler, kvadrat, "vann")
                    ).toLocaleString("nb-no")}
                  </td>
                  <td style={{ fontWeight: 800 }} className="text-end">
                    {(
                      beregnPris25(vannmaler, kvadrat, "avløp") +
                      beregnPris25(vannmaler, kvadrat, "vann")
                    ).toLocaleString("nb-no")}
                  </td>
                  <td style={{ fontWeight: 800 }} className="text-end">
                    {(
                      beregnPris25(vannmaler, kvadrat, "avløp") +
                      beregnPris25(vannmaler, kvadrat, "vann") -
                      (beregnPris24(vannmaler, kvadrat, "avløp") +
                        beregnPris24(vannmaler, kvadrat, "vann"))
                    ).toLocaleString("nb-no")}
                  </td>
                </>
              )}
              {type === "Vann" && (
                <>
                  <td style={{ fontWeight: 800 }} className="text-end">
                    {beregnPris24(vannmaler, kvadrat, "vann").toLocaleString(
                      "nb-no"
                    )}
                  </td>
                  <td style={{ fontWeight: 800 }} className="text-end">
                    {beregnPris25(vannmaler, kvadrat, "vann").toLocaleString(
                      "nb-no"
                    )}
                  </td>
                  <td style={{ fontWeight: 800 }} className="text-end">
                    {(
                      beregnPris25(vannmaler, kvadrat, "vann") -
                      beregnPris24(vannmaler, kvadrat, "vann")
                    ).toLocaleString("nb-no")}
                  </td>
                </>
              )}
              {type === "Avløp" && (
                <>
                  <td style={{ fontWeight: 800 }} className="text-end">
                    {beregnPris24(vannmaler, kvadrat, "avløp").toLocaleString(
                      "nb-no"
                    )}
                  </td>
                  <td style={{ fontWeight: 800 }} className="text-end">
                    {beregnPris25(vannmaler, kvadrat, "avløp").toLocaleString(
                      "nb-no"
                    )}
                  </td>
                  <td style={{ fontWeight: 800 }} className="text-end">
                    {(
                      beregnPris25(vannmaler, kvadrat, "avløp") -
                      beregnPris24(vannmaler, kvadrat, "avløp")
                    ).toLocaleString("nb-no")}
                  </td>
                </>
              )}
            </tr>
          </tbody>
        </Table>
        <div className="px-4">
          <b>Priser er inklusive mva</b> <br />
          <span>
            Regjeringen har i Statsbudsjettet for 2025 foreslått å redusere mva
            på vann og avløp fra 25 % til 15 % fra og med 1. mai 2025. <br />
            Dette er beregnet inn i prisforslaget for 2025.
          </span>{" "}
          <br />
          <br />
          <i>Vi tar forbehold om feil</i>
        </div>
      </div>
      <div className="col-12 py-2 bg-light">
        <hr />
        <div className="d-flex justify-content-center flex-column">
          <div className="d-flex flex-wrap align-items-center justify-content-center">
            <Card className="p-2 m-2">
              Tilgang på vann og/eller avløp hele døgnet hele året
            </Card>
            <Card className="p-2 m-2">Trygt, rent og godt drikkevann</Card>
            <Card className="p-2 m-2">Brannvannsdekning/slokkevann</Card>
            <Card className="p-2 m-2">Vakttjeneste og beredskap</Card>
            <Card className="p-2 m-2">Varslingssystem</Card>
            <Card className="p-2 m-2">Rørfornying og utbygging</Card>
            <Card className="p-2 m-2">Renset avløpsvann</Card>
          </div>
        </div>
      </div>
      <div className="col-12 bg-secondary text-white p-4 rounded-bottom">
        <h5>Kontakt seksjon for vann og avløp, Tromsø kommune</h5>
        <hr />
        <b>E-post:</b> <span>vannpost@tromso.kommune.no</span> <br />
        <b>Kundeservice:</b> <span>tel 91 69 12 10</span> <br />
        <b>Vakttjeneste:</b> <span>tel 97 00 90 10</span> <br />
        <br />
        <a
          href="https://tromso.kommune.no/vann"
          className="text-white"
          target="_blank"
        >
          Besøk vår nettside
        </a>
      </div>
    </div>
  );
}
