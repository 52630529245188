import React from "react";

export default function Template({ children }) {
  return (
    <div className="container-fluid">
      <div className="container">
        <header>
          <center>
            <a href="https://tromso.kommune.no/" taget="_blank">
              <img
                className="img-fluid"
                src="https://tromso.kommune.no/themes/custom/tinden/favicon.ico"
              />
            </a>
          </center>
        </header>
      </div>
      <div className="container">{children}</div>
    </div>
  );
}
