export const beregnPris24 = (vannmåler, input, type) => {
  const prisVann = 28.04;
  const prisAvløp = 33.24;

  const kbmVann = 18.69;
  const kbmAvløp = 22.16;

  const parsedInput = parseInt(input, 10);

  // Sjekk at parsedInput er et gyldig tall
  if (isNaN(parsedInput)) return 0;

  let pris = 0;

  if (vannmåler) {
    // Har vannmåler
    if (type === "vann") {
      pris = parsedInput * kbmVann;
    } else if (type === "avløp") {
      pris = parsedInput * kbmAvløp;
    }
  } else {
    // Har stipulert
    if (type === "vann") {
      pris = parsedInput * prisVann;
    } else if (type === "avløp") {
      pris = parsedInput * prisAvløp;
    }
  }

  return Math.round(pris * 1.25);
};

export const beregnPris25 = (vannmåler, input, type) => {
  const prisVann = 29.26;
  const prisAvløp = 34.68;

  const kbmVann = 19.5;
  const kbmAvløp = 23.12;

  const parsedInput = parseInt(input, 10);

  // Sjekk at parsedInput er et gyldig tall
  if (isNaN(parsedInput)) return 0;

  let pris = 0;

  if (vannmåler) {
    // Har vannmåler
    if (type === "vann") {
      pris = parsedInput * kbmVann;
    } else if (type === "avløp") {
      pris = parsedInput * kbmAvløp;
    }
  } else {
    // Har stipulert
    if (type === "vann") {
      pris = parsedInput * prisVann;
    } else if (type === "avløp") {
      pris = parsedInput * prisAvløp;
    }
  }

  const mndPris = pris / 12;
  const hPris = mndPris * 4 * 1.25;
  const lPris = mndPris * 8 * 1.15;

  return Math.round(hPris + lPris);
};
